import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';
import { Cooperative } from 'cooperatives/models/cooperative';
import { GenerationUnit } from 'generationUnits/models/generationUnit';

export type GroupId = number;
export type EntityId = number;
export type ContactId = number;
export type ContactGroupId = number;

export enum ContactGroupsExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export interface Groups {
  id: GroupId;
  groupName: string;
}

export interface ContactTypes {
  id: EntityId;
  contactTypeName: string;
}

export interface Contacts {
  id: ContactId;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactType: ContactTypes;
}

export interface ContactGroups {
  id: ContactGroupId;
  groups: Groups;
  contacts: Contacts;
  entities: Entities;
}

export interface Entities {
  cooperative: Cooperative;
  consumerUnit: ConsumerUnit;
  generationUnit: GenerationUnit;
}
