import { SyntheticEvent } from 'react';

import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import { ContactGroups } from 'contactGroups/models/contactGroups';

import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';

type Props = {
  contactGroups: ContactGroups;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function ContactGroupsTableItem({
  contactGroups: { id, contacts, entities, groups },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`generation-unit-lease-cycles-row-id-${id}`}>
      <TableBodyColumn>
        <p className="font-bold">{groups.groupName}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{contacts.contactEmail}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        <p>{contacts.contactPhone}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        {/*REFACTOR*/}
        <p>{49 + id}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        {/*REFACTOR*/}
        <p>{49 + id}</p>
      </TableBodyColumn>
      <TableBodyColumn>
        {/*REFACTOR*/}
        <p>{0 + id}</p>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
